<template>
  <div class="responsive">
    <div class="main-content mt-0">
      <div class="align-items-start min-vh-100">
        <div class="my-auto">
          <Nav />
          <div v-if="statusLoading != true" class="container center">
            <AnimationLoading />
          </div>
          <div v-if="compListaLlaves">
            <div class="">
              <div class="container-fluid">
                <div class="col-12">
                  <div class="contenedor">
                    <div class="lista">
                      <div class="ms-3 col col-lg-4 col-md-6 col-sm-5">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          @click="regresarAZonas()"
                        >
                          Listar zonas
                        </button>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="ms-3 col col-lg-4 col-md-6 col-sm-5">
                        <input
                          class="form-control shadow p-2"
                          type="text"
                          placeholder="Buscar Codigo de la llave "
                          v-model="filter"
                          @change="filter"
                          @keyup.prevent="filterTablaGestor()"
                        />
                      </div>
                      <div class="ms-2 col col-lg-9 col-md-4 col-sm-4">
                        <button
                          class="btn btnBuscar text-center shadow"
                          style="margin: auto"
                          @click="filterTablaGestor()"
                        >
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="card my-2">
                    <div
                      class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                    >
                      <div
                        class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3"
                      >
                        <h6 class="text-white text-capitalize ps-3">
                          Listado de Llaves CTPI
                        </h6>
                      </div>
                    </div>
                    <div class="card-body px-0 pb-2">
                      <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-center"
                              >
                                Zona
                              </th>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 p-2"
                              >
                                Ambiente
                              </th>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 ps-2 p-2"
                              >
                                Estado del ambiente
                              </th>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 ps-2 p-2"
                              >
                                codigo de la llave
                              </th>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 ps-2 p-2"
                              >
                                Estado
                              </th>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 ps-2 p-2"
                              >
                                Estado llave
                              </th>
                              <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 p-2"
                              >
                                Acciones
                              </th>
                            </tr>
                          </thead>
                          <tbody class="align-items-center">
                            <tr
                              v-for="(item, key) in listallave"
                              :key="key"
                              v-show="
                                (pag - 1) * NUM_RESULTS <= key &&
                                pag * NUM_RESULTS > key
                              "
                            >
                              <td>
                                <p
                                  class="text-xs font-weight-bold mb-0 text-center"
                                >
                                  {{ item.name_zone }}
                                </p>
                              </td>
                              <td>
                                <div class="d-flex px-3 py-1">
                                  <div
                                    class="d-flex flex-column justify-content-center"
                                  >
                                    <h6 class="mb-0 text-sm">
                                      {{ item.name_environments }}
                                    </h6>
                                    <p class="text-xs text-secondary mb-0"></p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p class="text-xs font-weight-bold mb-0">
                                  {{ item.status_environment }}
                                </p>
                              </td>
                              <td>
                                <p class="text-xs font-weight-bold mb-0">
                                  {{ item.code_key }}
                                </p>
                              </td>
                              <td>
                                <p class="text-xs font-weight-bold mb-0">
                                  {{ item.in_use }}
                                </p>
                              </td>
                              <td>
                                <p class="text-xs font-weight-bold mb-0">
                                  {{ item.status_key }}
                                </p>
                              </td>
                              <td class="row">
                                <div
                                  class="btn-toolbar btn-responsive-table"
                                  role="toolbar"
                                >
                                  <div
                                    v-if="item.in_use == 'Disponible'"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Asignar llave"
                                    class="pointer align-middle text-center text-sm text-danger btn-lg p-2"
                                    tooltip-flow="top"
                                    @click="
                                      ressetValueComponents(),
                                        (compRegistrarAsignacion = true),
                                        (idKeyProp = item)
                                    "
                                  >
                                    <fa
                                      class="moreSizeIcon iconOpen"
                                      icon="lock-open"
                                    />
                                  </div>
                                  <div
                                    v-if="item.in_use == 'No Disponible'"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="LLave en uso"
                                    class="pointer align-middle text-center text-sm text-danger btn-lg p-2"
                                    tooltip-flow="top"
                                    @click="llaveNoDiponible()"
                                  >
                                    <fa
                                      class="moreSizeIcon iconClose"
                                      icon="lock"
                                    />
                                  </div>
                                  <div
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Ver Historial"
                                    class="pointer align-middle text-center text-sm text-danger btn-lg p-2"
                                    tooltip-flow="top"
                                    @click="
                                      ressetValueComponents(),
                                        (compHistorial = true),
                                        (idLlaveProp = item.id_key)
                                    "
                                  >
                                    <fa
                                      class="moreSizeIcon iconRecord"
                                      icon="address-book"
                                    />
                                  </div>
                                  <div
                                    v-if="item.status_key == 'Pendiente'"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Verificar Llave"
                                    class="pointer align-middle text-center text-sm text-danger btn-lg p-2"
                                    tooltip-flow="top"
                                    @click="modifyKey(item)"
                                  >
                                    <fa
                                      class="moreSizeIcon iconClose"
                                      icon="check"
                                    />
                                  </div>
                                  <div
                                    v-if="item.in_use == 'No Disponible'"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=" Verificacion en Fisico "
                                    class="pointer align-middle text-center text-sm text-danger btn-lg p-2"
                                    tooltip-flow="top"
                                    @click="modifyKey(item)"
                                  >
                                    <fa
                                      class="moreSizeIcon iconClose"
                                      icon="check-double"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <nav
                      aria-label="Page navigation"
                      class="text-center center Page navigation example pointer"
                    >
                      <ul class="pagination justify-content-center">
                        <li class="page-item mx-2">
                          <a
                            aria-label="Previous"
                            v-show="pag != 1"
                            @click.prevent="pag -= 1"
                          >
                            <fa icon="arrow-left" />
                          </a>
                        </li>
                        <li class="mx-2">
                          <a
                            aria-label="Next"
                            v-show="(pag * NUM_RESULTS) / listallave.length < 1"
                            @click.prevent="pag += 1"
                          >
                            <fa aria-hidden="true" icon="arrow-right" />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="compRegistrarAsignacion">
            <div class="card-body">
              <div class="row">
                <div class="responsive box">
                  <button
                    type="button"
                    class="btn btn-lg btn btn-secondary btn-lg p-2"
                    @click="ressetValueComponents(), (compListaLlaves = true)"
                  >
                    Ver Lista de Llaves
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="compHistorial">
            <div class="card-body">
              <div class="row">
                <div class="responsive box">
                  <button
                    type="button"
                    class="btn btn-lg btn btn-secondary btn-lg p-2"
                    @click="ressetValueComponents(), (compListaLlaves = true)"
                  >
                    Ver Lista de Llaves
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="compRegistrarAsignacion">
            <Asignacion :keyProp="idKeyProp" />
          </div>
          <div v-if="compHistorial">
            <Historial :idLlaveProp="idLlaveProp" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "@/Axios";
import Swal from "sweetalert2";
import router from "@/router";
import Asignacion from "@/components/MenuGestor/Asignacion.vue";
import Historial from "@/components/MenuGestor/Historial.vue";
import AnimationLoading from "@/components/Animations/AnimationLoading.vue";
import Nav from "@/components/nav.vue";
export default {
  name: "tablaGestor",
  components: { Asignacion, AnimationLoading, Nav, Historial },
  data() {
    return {
      asignaciones: [],
      NUM_RESULTS: 10,
      pag: 1,
      idKeyProp: "",
      filter: "",
      idLlaveProp: "",
      compRegistrarAsignacion: false,
      compListaLlaves: false,
      compHistorial: false,
      listallaves: [],
      listallave: [],
      statusLoading: false,
    };
  },
  /*eslint-disable*/ methods: {
    filterTablaGestor() {
      let arreglofiltrado = [];
      for (let key of this.listallaves) {
        let zone = key.name_zone.toLocaleLowerCase();
        let name = key.name_environments.toLocaleLowerCase();
        let status = key.status_environment.toLocaleLowerCase();
        let code = key.code_key.toLocaleLowerCase();
        let use = key.in_use.toLocaleLowerCase();
        if (
          zone.indexOf(this.filter.toLocaleLowerCase()) >= 0 ||
          name.indexOf(this.filter.toLocaleLowerCase()) >= 0 ||
          status.indexOf(this.filter.toLocaleLowerCase()) >= 0 ||
          code.indexOf(this.filter.toLocaleLowerCase()) >= 0 ||
          use.indexOf(this.filter.toLocaleLowerCase()) >= 0
        ) {
          arreglofiltrado.push(key);
        }
        this.listallave = arreglofiltrado;
      }
      if (this.listallave.length == 0) {
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1500,
          text: "No se encontró el gestor que coincidan con la busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.listallave = this.listallaves;
      }
    },
    ressetValueComponents() {
      (this.compListaLlaves = false),
        (this.compRegistrarAsignacion = false),
        (this.compHistorial = false);
    },
    async listKeys() {
      Axios()
        .get("listar_llaves_zona/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.listallaves = response.data.results;
            this.listallave = response.data.results;
          } else {
            Swal.fire({
              title: "llaves no encontrados",
              icon: "error",
              text: "",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#f27474",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.statusLoading = true;
          this.compListaLlaves = true;
          if (this.listallaves.length == 0) {
            Swal.fire({
              title: "No hay llaves asignadas a esta zona",
              icon: "info",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#238276",
            }).then((respuesta) => {
              if (respuesta.isConfirmed) {
                router.push({ name: "MenuGestor" });
              }
            });
          }
        });
    },
    llaveNoDiponible() {
      Swal.fire({
        icon: "info",
        title: "No se puede entregar una llave que esta en uso",
        showConfirmButton: false,
        timer: 1200,
      });
    },
    regresarAZonas() {
      router.push({ name: "MenuGestor" });
    },
    async modifyKey(item) {
      Swal.fire({
        title: "Seguro desea entregar la llave?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#00b347",
        denyButtonText: `Don't save`,
        cancelButtonColor: "#d33",
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          Axios()
            .put("llave_asignacion/" + item.id_key, {
              code_key: item.code_key,
              in_use: "Disponible",
              status_key: "Verificado",
            })
            .then((response) => {
              if (response.data.status == "success") {
                this.cambiarfecha(item);
                Swal.fire({
                  title: "Llave entregada correctamente",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#238276",
                }).then((respuesta) => {
                  if (respuesta.isConfirmed) {
                    location.reload();
                  }
                });
              } else {
                Swal.fire({
                  title: "Error al entregar llave",
                  icon: "error",
                  text: "Hubo un problema al entregar la llave",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#008000",
                });
              }
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            });
        }
      });
    },
    async cambiarfecha(item) {
      let date = new Date();
      let mes = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      let anio = date.getFullYear();
      let hora =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      Axios()
        .put("actualizar_fecha/" + item.id_key, {
          returned_on:
            date.getUTCDate() +
            "-" +
            mes[date.getMonth()] +
            "-" +
            anio +
            "-" +
            hora,
        })
        .then((response) => {
          if (response.data.status == "success") {
            Swal.fire({
              title: "Actualizacion correctamente",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#238276",
            }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
          } else {
            Swal.fire({
              title: "Error al entregar llave",
              icon: "error",
              text: "Hubo un problema al entregar la llave",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },
  },
  mounted() {
    this.listKeys();
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.moreSizeIcon {
  font-size: 1.5rem;
}
.iconOpen {
  color: green;
}
.iconClose {
  color: rgba(0, 0, 0, 0.877);
}
.iconRecord {
  color: rgb(0, 105, 128);
}
.btnBuscar {
  background-color: #7b809a;
  color: white;
}
.btnBuscar:hover {
  background-color: #7b809a;
  color: white;
}

</style>
