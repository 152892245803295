<template>
  <div>
    <div>
      <div class="container-fluid">
        <div class="col-12 pb-4">
          <div class="card">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="
                  bg-gradient-primary
                  shadow-primary
                  border-radius-lg
                  pt-4
                  pb-3
                "
              >
                <h6 class="text-white text-capitalize ps-2">
                  Asignacion de LLaves
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="
                        col-lg-6 col-md-6 col-sm-12
                        row
                        px-6
                        mt-2
                        col-12
                        media
                        mt-2
                      "
                    >
                      <div
                        class="page-header input-group-text card"
                        style="box-shadow: 0px 0px 14px 0px #fd671a !important"
                      >
                        <img
                          loading="lazy"
                          src="../../../src/assets/ambiente.jpeg"
                          class="card-img-top"
                          style="width: 100%; height: 14rem"
                          alt="..."
                        />
                        <div class="card-body">
                          <h5 class="card-title p-2">
                            {{ keyProp.name_environments }}
                          </h5>
                          <p class="card-text p-2">{{ keyProp.code_key }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-6 col-md-6 col-sm-12
                        row
                        px-6
                        mt-2
                        col-sm-12 col-12
                        media
                        mt-2
                      "
                      v-if="user.length === 0"
                    >
                      <div
                        class="page-header input-group-text card"
                        style="box-shadow: 0px 0px 14px 0px #fd671a !important"
                      >
                        <img
                          loading="lazy"
                          src="../../../src/assets/user_transfer.png"
                          class="card-img-top"
                          style="width: 100%; height: 14rem"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div
                      v-else
                      class="
                        col-lg-6 col-md-6 col-sm-12
                        row
                        px-6
                        mt-2
                        col-sm-12 col-12
                        media
                        mt-2
                      "
                    >
                      <div
                        class="page-header input-group-text card"
                        style="box-shadow: 1px 1px 14px 1px #5aaad1 !important"
                      >
                        <img
                          v-lazy="user.image_user"
                          class="card-img-top"
                          style="width: 100%; height: 14rem"
                          alt="..."
                        />
                        <div class="card-body">
                          <h5 class="card-title p-2">
                            {{ user.name_person + " " + user.last_name }}
                          </h5>
                          <p class="card-text p-2">{{ user.email_user }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      v-if="!findUser"
                      class="input-group input-group-dynamic"
                    >
                      <input
                        v-model="identificationUser"
                        type="number"
                        class="form-control"
                        placeholder="Identificacion del instructor"
                      />
                      <button
                        style="
                          border: none;
                          background-color: transparent;
                          outline: none;
                        "
                        v-if="identificationUser.length === 0"
                        class="transparent text-center center"
                        type="text"
                        disabled
                      >
                        <fa icon="search" />
                      </button>
                      <button
                        v-else
                        style="
                          border: none;
                          background-color: transparent;
                          outline: none;
                        "
                        class="transparent text-center center"
                        type="text"
                        @click="getUser(identificationUser)"
                      >
                        <fa icon="search" />
                      </button>
                    </div>
                    <div v-else-if="findUser" class="center text-center">
                      <div
                        class="spinner-border text-warning text-center center"
                        role="status"
                      ></div>
                    </div>

                    <div class="text-center" v-if="user.length === 0">
                      <button
                        type="button"
                        class="
                          btn btn-lg
                          bg-gradient-secondary
                          btn-lg
                          w-100
                          mt-4
                          mb-0
                          p-2
                        "
                        disabled
                      >
                        Asignar Llave
                      </button>
                    </div>
                    <div class="text-center" v-else>
                      <div class="text-center" v-if="statusloading">
                        <AnimationLoading></AnimationLoading>
                      </div>
                      <button
                        v-if="!statusloading"
                        type="button"
                        class="
                          btn btn-lg
                          bg-gradient-secondary
                          btn-lg
                          w-100
                          mt-4
                          mb-0
                          p-2
                        "
                        @click="makeAssigment()"
                      >
                        Asignar Llave
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
/*eslint-disable */
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimationLoading from "@/components/Animations/AnimationLoading.vue";

export default {
  components: {
    AnimationLoading,
  },

  name: "asignacion",

  /*eslint-disable */
  props: {
    keyProp: Object,
  },
  data() {
    return {
      statusloading: false,
      identificationUser: "",
      user: [],
      image_environment: "",
      llave: [],
      findUser: false,
      date: null,
      mes:null,
      mes:null,
      hora:null,
    };
  },
  methods: {
    getUser($identification) {
      this.findUser = true;
      Axios()
        .get("lista_usuario/" + $identification)
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.results.zone_id == this.keyProp.id_zone) {
              this.user = response.data.results;
            } else {
              this.user = [];
              Swal.fire({
                title: "Error de asignacion ",
                icon: "error",
                text: "No se le puede asignar una llave a un instructor con zonas diferentes",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#008f39",
              });
            }
          } else if (response.data.status == "info") {
            Swal.fire({
              title: "Identificacion no encontrada",
              icon: "error",
              text: "",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008f39",
            });
            this.user = [];
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.findUser = false;
        });
    },


    /* ------------------------------------------------------------------------------------------------------------------- */

    makeAssigment() {
      this.date = new Date();
       this.mes = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
       this.anio =  this.date.getFullYear();
      this.hora=
         this.date.getHours() + ":" +  this.date.getMinutes() + ":" +  this.date.getSeconds();
      Axios()
        .get("/mostraruso/" + this.keyProp.id_key)
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response.data.results);

            if (response.data.results.in_use == "Disponible") {
              this.nuevo();
            } else if (response.data.results.in_use == "No Disponible") {
              Swal.fire({
                title: "Ya se encuentra asignada",
                icon: "error",
                text: "",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#f27474",
              }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
            }
            // this.mostrar_image();
          } else if (response.data.status == "INFO") {
            this.nuevo();
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },

    async modifyKey() {
      this.keyProp.in_use = "No Disponible";
      Axios()
        .put("llaves_actualizacion/" + this.keyProp.id_key, this.keyProp)
        .then((response) => {
          if (response.data.status == "success") {
            Swal.fire({
              title: "Asignación realizada correctamente",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#238276",
            }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
          } else if (returned_on == "En uso") {
            Swal.fire({
              title: "Error de Asignación",
              icon: "error",
              text: "llave ya asignada",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
          
          } else {
            Swal.fire({
              title: "Error de Asignación",
              icon: "error",
              text: "Hubo un problema al realizar la asignación",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },

    async nuevo() {
      Axios()
        .post("almacenar_asignacion", {
          user_id: this.user.id_user,
          key_id: this.keyProp.id_key,
          transfered_on:
             this.date.getUTCDate() +
            "-" +
            this.mes[ this.date.getMonth()] +
            "-" +
            this.anio +
            "-" +
            this.hora,
          returned_on: "En uso",
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.modifyKey();
          } else {
            Swal.fire({
              title: "Error de Asignación",
              icon: "error",
              text: "Hubo un problema al realizar la asignación",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.statusloading = true;
        });
    },
    async listKeys() {
      Axios()
        .get("listar_llaves_zona/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.llave = response.data.results;
            // this.mostrar_image();
          } else {
            Swal.fire({
              title: "llaves no encontrados",
              icon: "error",
              text: "",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#f27474",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },
    // async mostrar_image() {
    //   for (let index = 0; index < this.llave.length; index++) {
    //     this.image_environment = this.llave[index].image_environment;
    //   }
    // },
  },
  async mounted() {
    this.listKeys();
  },
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@media (max-width: 711px) {
  .col-lg-6.col-md-6.col-sm-12.row.px-6.mt-2.col-12 {
    margin: 0%;
    padding: 0%;
    width: 100%;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 0%;
  }
  .page-header.input-group-text.card {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .media {
    padding: 10px !important;
    transition: all 150ms;
    /* margin: 0px !important; */
    justify-content: spa;
  }
}
</style>