<template>
  <div v-if="!statusLoading" class="container center"><AnimationLoading /></div>
  <div v-else class="container-fluid">
    <p
      class="card bg-gradient-dark font-weight-bolder text-uppercase text-center p-2 responsive text-white"
    >
      Historial de la llave
    </p>
    <div class="row mt-2 text-center">
      <div
        class="col-lg-4 mb-4 text-center"
        v-for="(historial, key) in listaHistorial"
        :key="key"
      >
        <div v-if="historial.returned_on == estado">
          <div
            class="card p-2 text-center shadow-darkUse"
            style="box-shadow: #5aaad1 1px 1px 15px 1px"
          >
            <div class="text-center center">
              <div class="col-md-12 col-12">
                <div class="text-center">
                  <img
                    loading="lazy"
                    class="card-img-top"
                    style="width: 100%; height: 10rem"
                    v-lazy="historial.image_user"
                  />
                </div>
                <div class="pt-0 p-3 text-center">
                  <h6 class="text-center mb-0">
                    {{ historial.name_person + " " + historial.last_name }}
                  </h6>
                  <span class="text-xs">Información importante </span>
                  <div class="text-center center">
                    <hr class="horizontal dark my-3" />
                    <p class="mb-0">{{ historial.email_user }}</p>
                    <p class="mb-0">{{ historial.identification_user }}</p>
                    <p class="mb-0">
                      {{ "Codigo de llave: " + historial.code_key }}
                    </p>
                    <h5 class="mb-0">{{ historial.returned_on }}</h5>
                    <br />
                    <fa
                      style="color: red"
                      v-if="historial.returned_on == estado"
                      icon="flag"
                      class="moreSizeIcon_color"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="card p-2 text-center shadow-dark">
          <div class="text-center center">
            <div class="col-md-12 col-12">
              <div class="text-center">
                <img
                  loading="lazy"
                  class="card-img-top"
                  style="width: 100%; height: 10rem"
                  v-lazy="historial.image_user"
                />
              </div>
              <div class="pt-0 p-3 text-center">
                <h6 class="text-center mb-0">
                  {{ historial.name_person + " " + historial.last_name }}
                </h6>
                <span class="text-xs">Información importante </span>
                <div class="text-center center">
                  <hr class="horizontal dark my-3" />
                  <p class="mb-0">{{ historial.email_user }}</p>
                  <p class="mb-0">{{ historial.identification_user }}</p>
                  <p class="mb-0">
                    {{ "Codigo de llave: " + historial.code_key }}
                  </p>
                  <h5 class="mb-0">{{ historial.returned_on }}</h5>
                  <br />
                  <fa
                    style="color: red"
                    v-if="historial.returned_on == estado"
                    icon="flag"
                    class="moreSizeIcon_color"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/ import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimationLoading from "@/components/Animations/AnimationLoading.vue";
export default {
  name: "OcupacionAmbientes",
  props: { idLlaveProp: String },
  components: { AnimationLoading },
  data() {
    return { statusLoading: false, estado: "En uso", listaHistorial: [] };
  },
  methods: {
    obtenerHistorial() {
      Axios()
        .get("historial/" + this.idLlaveProp)
        .then((response) => {
          if (response.data.status == "success") {
            this.listaHistorial = response.data.results;
            this.statusLoading = true;
            if (this.listaHistorial.length == 0) {
              Swal.fire({
                title: "No existe un historial de esta llave",
                icon: "info",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#238276",
              }).then((respuesta) => {
                if (respuesta.isConfirmed) {
                  location.reload();
                }
              });
            }
          } else {
            Swal.fire({
              title: "Error al obtener historial",
              icon: "error",
              text: "",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.statusLoading = true;
        });
    },
  },
  mounted() {
    this.obtenerHistorial();
  },
};
</script>
<style>
.moreSizeIcon_color {
  font-size: 1.5rem;
  color: #fd671a;
}
.shadow-darKUse {
  background-color: #fd671a !important;
  box-shadow: 10px #fd671a !important;
}
</style>
